.login.login-5 .login-signin,
.login.login-5 .login-signup,
.login.login-5 .login-forgot {
  display: none;
}
.login.login-5.login-signin-on .login-signup {
  display: none;
}
.login.login-5.login-signin-on .login-signin {
  display: block;
}
.login.login-5.login-signin-on .login-forgot {
  display: none;
}
.login.login-5.login-signup-on .login-signup {
  display: block;
}
.login.login-5.login-signup-on .login-signin {
  display: none;
}
.login.login-5.login-signup-on .login-forgot {
  display: none;
}
.login.login-5.login-forgot-on .login-signup {
  display: none;
}
.login.login-5.login-forgot-on .login-signin {
  display: none;
}
.login.login-5.login-forgot-on .login-forgot {
  display: block;
}

.login.login-5 .login-form {
  width: 100%;
  max-width: 450px;
}

@media (max-width: 575.98px) {
  .login.login-5 .login-form {
    width: 100%;
    max-width: 100%;
  }
}